module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"K. Anthony Portfolio","short_name":"KAnthonyPortfolio","description":"Kristin makes work you can play. Find out more on their portfolio.","lang":"en","start_url":"/","background_color":"#F4F4F4","theme_color":"#37ABD4","display":"standalone","icon":"src/assets/images/knanthony_logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d8f7e485f2d5eaf9fde27a0bf0a9f6a4"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
